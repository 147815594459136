.modal {
  h3.top-title {
    font-size: 18px;
    color: #e74c3c;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding-top: 10px;
    padding-top: 5px;
  }
  p.message{
    text-align: center;
    font-size: $font-size-medium;
    padding: 25px 20px;
  }
}
