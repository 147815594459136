/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import '_sass/abstracts/___mixins.scss';
@import '_sass/abstracts/___paths.scss';
@import '_sass/abstracts/__colors.scss';
@import '_sass/abstracts/__layout.scss';
@import '_sass/abstracts/__typography.scss';
@import '_sass/base/__bootstrap.scss';
@import '_sass/base/__components.scss';
@import '_sass/base/__reset.scss';
@import '_sass/base/__roberto.scss';
@import '_sass/base/_base.scss';
@import '_sass/components/_animations.scss';
@import '_sass/components/_breadcumb.scss';
@import '_sass/components/_button.scss';
@import '_sass/components/_card.scss';
@import '_sass/components/_divider.scss';
@import '_sass/components/_fonts.scss';
@import '_sass/components/_horizontal-trip-steps.scss';
@import '_sass/components/_icons.scss';
@import '_sass/components/_input.scss';
@import '_sass/components/_jumbo.scss';
@import '_sass/components/_label.scss';
@import '_sass/components/_loader.scss';
@import '_sass/components/_mapview.scss';
@import '_sass/components/_modal.scss';
@import '_sass/components/_slider.scss';
@import '_sass/components/_subnav.scss';
@import '_sass/components/_tooltip.scss';
@import '_sass/components/_trip-steps.scss';
@import '_sass/components/_whiteframe.scss';
@import 'components/alert/alert.modal.scss';
@import 'components/complete/complete.scss';
@import 'components/complete/modal/alert.modal.scss';
@import 'components/cookielaw/cookielaw.scss';
@import 'components/datepicker/angular-datepicker.scss';
@import 'components/dropdown/dropdown.scss';
@import 'components/filter-save/filter.modal.scss';
@import 'components/maps/maps.scss';
@import 'components/markers/markers.scss';
@import 'components/menu/menu.scss';
@import 'components/placepicker-fast/placepicker.scss';
@import 'components/placepicker-nice/placepicker.scss';
@import 'components/placepicker-slim/placepicker.scss';
@import 'components/placepicker/placepicker.scss';
@import 'components/quote-details/classic/classic.scss';
@import 'components/quote-details/new/new.scss';
@import 'components/quote-details/quote-details.scss';
@import 'components/quote-details/reject/alert.modal.scss';
@import 'components/quote-details/tour/tour.scss';
@import 'components/slider/slider.scss';
@import 'components/table/table.scss';
@import 'components/timepicker/timepicker.scss';
@import 'components/uploader-directive/uploader.scss';
@import 'components/uploader-doc/uploader.scss';
@import 'components/uploader/uploader.scss';
@import 'components/vehiclepicker/vehiclepicker.modal.scss';
@import 'elements/advantages/advantages.scss';
@import 'elements/auth/auth.scss';
@import 'elements/auth/step1/name.scss';
@import 'elements/auth/step2/poc.scss';
@import 'elements/auth/step3/company.scss';
@import 'elements/auth/step4/cheer.scss';
@import 'elements/auth/step5/additional.scss';
@import 'elements/auth/step6/firstcompleted.scss';
@import 'elements/auth/step7/notification.scss';
@import 'elements/auth/step8/requesttypes.scss';
@import 'elements/auth/step9/vehicle.scss';
@import 'elements/clients/clients.scss';
@import 'elements/confirmemail/confirmemail.scss';
@import 'elements/dashboard/dashboard.scss';
@import 'elements/footer/footer.scss';
@import 'elements/howitworks/howitworks.scss';
@import 'elements/navbar/navbar.modal.scss';
@import 'elements/navbar/navbar.scss';
@import 'elements/offering-supplier/offering.scss';
@import 'elements/offering/offering.scss';
@import 'elements/planner-shuttle/planner.scss';
@import 'elements/planner-topic/planner.scss';
@import 'elements/planner-topic/services/bus/bus.scss';
@import 'elements/planner-topic/services/citytour/citytour.scss';
@import 'elements/planner-topic/services/disposal/disposal.scss';
@import 'elements/planner-topic/services/end/end.scss';
@import 'elements/planner-topic/services/pickup/pickup.scss';
@import 'elements/planner-topic/services/return/return.scss';
@import 'elements/planner-topic/services/shuttle/shuttle.scss';
@import 'elements/planner-topic/services/transfer/transfer.scss';
@import 'elements/planner-topic/services/transit/transit.scss';
@import 'elements/planner-tour/planner.scss';
@import 'elements/planner/planner.scss';
@import 'elements/requirements/requirements.scss';
@import 'elements/saysaboutus/saysaboutus.scss';
@import 'elements/signup/signup.scss';
@import 'elements/successstories/saysaboutus.scss';
@import 'elements/whyus/whyus.scss';
@import 'views/external/details.scss';
@import 'views/landing/e404.scss';
@import 'views/landing/suppliers.scss';
@import 'views/supplier/accepted.scss';
@import 'views/supplier/black-list.scss';
@import 'views/supplier/book/attachments/attachments.scss';
@import 'views/supplier/book/drivers/drivers.scss';
@import 'views/supplier/book/email/email.scss';
@import 'views/supplier/book/network/network.scss';
@import 'views/supplier/book/newquote.scss';
@import 'views/supplier/book/overview/overview.scss';
@import 'views/supplier/book/partners/partners.scss';
@import 'views/supplier/book/plan/plan.scss';
@import 'views/supplier/book/price/price.scss';
@import 'views/supplier/book/publish/publish.scss';
@import 'views/supplier/book/standards/standards.scss';
@import 'views/supplier/book/supplier/supplier.scss';
@import 'views/supplier/book/users/users.scss';
@import 'views/supplier/credentials.scss';
@import 'views/supplier/details.scss';
@import 'views/supplier/places.scss';
@import 'views/supplier/profile.scss';
@import 'views/supplier/quotes.scss';
@import 'views/supplier/subscribe/subscribe.scss';
@import 'views/supplier/tables/network/network.scss';
@import 'views/supplier/tables/pricing/modal/alert.modal.scss';
@import 'views/supplier/tables/pricing/pricing.scss';
@import 'views/supplier/tables/sent-quote/list.scss';
@import 'views/supplier/tables/vehicles/modal/alert.modal.scss';
@import 'views/supplier/tables/vehicles/vehicles.scss';
@import 'views/supplier/trips.scss';
@import 'views/supplier/vehicles.scss';
// endinjector
