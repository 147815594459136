.mapview {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .view {
    background-color: $color-content;
    padding-left: 5px;
    flex: 1;
    flex-grow: 1;
    display:flex;
    position: relative;
    >.row {
      width: 103%;
    }
    .content {
      flex: 1;
      flex-grow: 1;
      display:flex;
      width: 100%;
      border-right: 1px solid $color-border;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .maps {
      flex: 1;
      flex-grow: 1;
      display: flex;
      width: 100%;

      background-color: #b3d1ff;
      position: relative;
      background: image("placeholders/maps-placeholder-hq.jpg") no-repeat center center;
      background-size: cover;
      .trip {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 90px;
        z-index: 1000;
        background-color: rgba($color-content, 0.8);
        border-bottom: $color-border 1px solid;
        p {
          text-size: $font-size-medium;
          text-align: center;
          line-height: 90px;
          b {
            font-weight: bold;
          }
        }
      }
      .angular-google-map-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
  }
}
