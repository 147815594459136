// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
}
.pac-container {
  z-index: 1000000 !important;
}
/**
 * Basic styles for page size
 */
html, body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  min-width: 600px;
}

/**
 * Basic styles for body
 */
body {
  color: $color-text;
  font-family: $text-font;
  font-size: $font-size-regular;
  background-color: $color-page;
  line-height: normal;
}

main {
  min-height: calc(100vh - (307px + 61px));
}

/**
 * Basic styles for links
 */
a, .a{
  color: $color-brand-primary;
  text-decoration: none;
  cursor: pointer;

  @include on-event {
    color: $color-brand-primary;
    text-decoration: underline;
  }
}
