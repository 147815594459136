// -----------------------------------------------------------------------------
// This file contains all styles related to the whiteframe component.
// -----------------------------------------------------------------------------

.whiteframe-container {
  margin-top: 15px;
  &:last-child {
    margin-bottom: 15px;
  }
  .whiteframe {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2.heading {
    font-size: $font-size-headline;
    margin-bottom: 5px;
  }
  &.whiteframe, .whiteframe {
    background-color: $color-grey-white;
    width: 100%;
    border-radius: 2.5px;
    position: relative;
    border: 1px solid $color-border;
    &.arrow-top {
      &::before {
        display: block;
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        top: -10px;
        left: 50%;
        margin-left: -9px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #CBCBCB;
      }
      &::after {
        display: block;
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        top: -9px;
        left: 50%;
        margin-left: -9px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #FFF;
      }
    }
    &.arrow-bottom {
      &::before {
        display: block;
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        bottom: -10px;
        left: 50%;
        margin-left: -9px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid #CBCBCB;
      }
      &::after {
        display: block;
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        bottom: -9px;
        left: 50%;
        margin-left: -9px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid #FFF;
      }
    }
    .header {
      padding: 15px;
      border-bottom: 1px solid $color-border;
      &.center {
        text-align: center;
      }
      h4 {
        font-size: $font-size-large;
        font-weight: bold;
      }
      .small {
        color: $color-text-muted;
        font-size: $font-size-small;
        font-weight: normal;
      }
      .right {
        text-align: right;
      }
      p {
        line-height: 21px;
      }
    }
    .content-details {
        padding: 5px;
        border-top: 1px solid $color-border;
      .services-20 {
        ul {
          margin-left: 2.5px;
          margin-top: 5px;
          margin-bottom: 5px;
          li {
            cursor: pointer;
            margin-right: 2.5px;
            color: $color-border;
            display: inline-block;
            .icon {
              font-size: $font-size-large;
            }
            &.active {
              color: $color-brand-primary;
            }
          }
        }
      }
      .right-action {
        text-align: right;
      }
    }
    .foot {
      padding: 5px;
      border-top: 1px solid $color-border;
    }
    .heading {
      h2 {
        font-size: $font-size-large;
        padding-left: 15px;
        padding-top: 15px;
      }
    }
    .content {
      padding: 10px;
    }
    .content-pointer {
      background-color: $color-grey-light;
      cursor: pointer;
      &:hover {
        background-color: $color-grey-white;
      }
      &.active {
        background-color: $color-grey-white;
      }
    }
    a {
      &.content-pointer {
        text-decoration: none;
        display: block;
        color: inherit;
      }
    }
    .content-profile {
      padding-right: 15px;
      padding-left: 15px;
      line-height: 1.5;
      .name, .email, .phone, .address, .iva {
        font-size: $font-size-medium;
        .label {
          font-weight: bold;
        }
        .small {
          color: $color-text-muted;
          font-size: $font-size-small;
          font-weight: normal;
        }
      }
      .company {
        font-style: italic;
        font-size: $font-size-regular;
      }
    }
    .content-checkout {
      table {
        table-layout: fixed;
        width: 100%;
        tr:nth-child(1) {
          border-top: none;
        }
        .spanit {
          width: 100%
        }
        .status {
          .state {
            line-height: 30px;
          }
          .tag, .tooltips {
            float: right;
            &:after {
              clear: both;
            }
          }
        }
        .code {
          font-size: $font-size-small;
          vertical-align: bottom;
          direction: rtl;
        }
        .line {
          border-top: 1px solid $color-border;
        }
        .disabled {
          text-decoration: line-through;
          color: $color-text-muted;
          background-color: $color-content;
        }
        .solid {
          border-top: 2px solid $color-border;
        }
        tbody {
          tr {
            &:first-child {
              td {
                padding-top: 8px;
              }
            }
            &:last-child {
              td {
                padding-bottom: 8px;
              }
            }
          }
        }
        td {
          width: 100%;
          line-height: 1.5;
          vertical-align: top;
        }
        td:nth-child(1) {
          padding-left: 16px;
        }
        td:nth-child(2) {
          padding-right: 16px;
          text-align: right;
          white-space: nowrap;
        }
        td.colspan {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
    .content-confirmation {
      h4 {
        font-size: $font-size-medium;
      }
      line-height: 1.6;
    }
    .content-form {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }
    .content-rules {
      .info {
        display: inline-block;
        color: $color-brand-mid;
        &:hover {
          cursor: pointer;
          color: $color-brand-primary;
        }
      }
      p {
        margin-top: 10px;
      }
      ul {
        margin-bottom: 10px;
        li {
          padding-bottom: 10px;
          border-bottom: $color-border 2px dotted;
          .table {
            display: table;
            margin: auto;
            padding-top: 10px;
            .icon {
              cursor: pointer;
              text-align: center;
              font-size: $font-size-h1;
              &:hover {
                color: $color-brand-primary;
              }
              &.disabled {
                color: $color-content;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
    .content-float {
      overflow: auto;
      .right {
        margin-left: 10px;
        float: right;
      }
      .left {
        margin-right: 10px;
        float: left;
      }
      p {
        line-height: 26px;
      }
      &:after {
        clear: both;
      }
    }
    .content-nocontent {
      padding-top: 50px;
      padding-bottom: 50px;
      min-height: 200px;
      text-align: center;
      font-size: $font-size-large;
      .icon {
        font-size: $font-size-h2;
      }
      .message {
        font-size: $font-size-headline;
        line-height: 1.6;
      }
    }
    .content-evidence {
      text-align: center;
      h4 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: $font-size-headline;
      }
      p {
        padding: 5px 15px 20px 15px;
      }
      .btn {
        margin-bottom: 10px;
      }
    }
    .content-map {
      .angular-google-map-container {
        min-height: 240px;
      }
    }
    .content-map-order {
      .angular-google-map-container {
        height: 180px;
      }
    }
    .content-services {
      padding: 10px 10px 0 10px;
      p {
        margin-top: 10px;
        margin-left: 10px;
      }
      ul {
        display: inline-block;
        padding: 10px 0 0 0;
        &.services-2 {
          .icon {
            font-size: 24px;
            width: 36px;
            height: 36px;
          }
          li {
            padding: 16px;
            padding-bottom: 4px;
            height: 40px;
            margin-bottom: 0px;
            width: 50%;
            @include respond-to(md) {
              width: 50%;
            }
          }
        }
        &.services-5 {
          li {
            width: 10%;
            @include respond-to(md) {
              width: 20%;
            }
          }
        }
        &.services-10 {
          li { width: 10% }
        }
        li {
          float: left;
          color: #d1d1d1;
          cursor: pointer;
          text-align: center;
          margin-bottom: 10px;
          height: $font-size-h1 + 14px;
          width: $font-size-h1 + 14px;
          &:after {
            clear: both;
          }
          .icon {
            border: #d1d1d1 2px solid;
            border-radius: 5px;
            padding: 2.5px;
            font-size: $font-size-headline;
            @include respond-to(sm) {
              font-size: $font-size-h1;
              height: $font-size-h1 + 14px;
              width: $font-size-h1 + 14px;
            }
            height: $font-size-headline + 14px;
            width: $font-size-headline + 14px;
          }
          &.active {
            color: $color-brand-primary;
            .icon {
              border-color: $color-brand-primary;
            }
          }
        }
      }
    }

    .content-services-text {
      padding: 10px 10px 0 10px;
      p {
        margin-top: 10px;
        margin-left: 10px;
      }
      ul {
        display: inline-block;
        padding: 10px 0 0 0;
        &.services-2 {
          .icon {
            font-size: 24px;
            width: 36px;
            height: 36px;
          }
          li {
            padding: 16px;
            padding-bottom: 4px;
            height: 40px;
            margin-bottom: 0px;
            width: 50%;
            @include respond-to(md) {
              width: 50%;
            }
          }
        }
        &.services-5 {
          li {
            width: 10%;
            @include respond-to(md) {
              width: 20%;
            }
          }
        }
        &.services-10 {
          li { width: 10% }
        }
        li {
          float: left;
          color: #d1d1d1;
          cursor: pointer;
          text-align: center;
          margin-bottom: 10px;
          height: $font-size-h1 + 14px + $font-size-h1;
          width: $font-size-h1 + 14px;

          p {
            color: black;
          }
          &:after {
            clear: both;
          }
          .icon {
            border: #d1d1d1 2px solid;
            border-radius: 5px;
            padding: 2.5px;
            font-size: $font-size-headline;
            @include respond-to(sm) {
              font-size: $font-size-h1;
              height: $font-size-h1 + 14px;
              width: $font-size-h1 + 14px;
            }
            height: $font-size-headline + 14px;
            width: $font-size-headline + 14px;
          }
          &.active {
            color: $color-brand-primary;
            .icon {
              border-color: $color-brand-primary;
            }
          }
        }
      }
    }
    .content-services-bottom {
      padding: 10px 10px 0 10px;
      p {
        margin-top: 10px;
        // margin-left: 10px;
      }
      ul {
        display: inline-block;
        padding: 10px 0 0 0;
        &.services-2 {
          .icon {
            font-size: 24px;
            width: 36px;
            height: 36px;
          }
          li {
            padding: 16px;
            padding-bottom: 12px;
            height: 40px;
            margin-bottom: 0px;
            width: 50%;
            @include respond-to(md) {
              width: 50%;
            }
          }
        }
        &.services-5 {
          li {
            width: 10%;
            @include respond-to(md) {
              width: 20%;
            }
          }
        }
        &.services-10 {
          li { width: 10% }
        }
        @media only screen and (max-width: 600px) {
          &.services-10  {
            li { width: 20% }
          }
        }
        li {
          float: left;
          color: #d1d1d1;
          cursor: pointer;
          text-align: center;
          margin-bottom: 10px;
          height: $font-size-h1 + 14px + $font-size-h1;
          width: $font-size-h1 + 14px;

          p {
            color: black;
          }
          &:after {
            clear: both;
          }
          .icon {
            border: #d1d1d1 2px solid;
            border-radius: 5px;
            padding: 2.5px;
            font-size: $font-size-headline;
            @include respond-to(sm) {
              font-size: $font-size-h1;
              height: $font-size-h1 + 14px;
              width: $font-size-h1 + 14px;
            }
            height: $font-size-headline + 14px;
            width: $font-size-headline + 14px;
          }
          &.active {
            color: $color-brand-primary;
            .icon {
              border-color: $color-brand-primary;
            }
          }
        }
      }
    }
    .content-itineray {
      border-top: $color-border 1px dotted;
      background: rgba($color-grey-light, 0.5);
      padding-top: 10px;
    }

    .content-collpase {
      &.ng-enter {
        transition: 0.5s;
        opacity: 0;
        max-height: 0px;
      }
      &.ng-enter-active {
        opacity: 1;
        max-height: 105px;
      }
      &.ng-leave {
        transition: 0.5s;
        opacity: 1;
        max-height: 105px;
      }
      &.ng-leave-active {
        opacity: 0;
        max-height: 0px;
      }
    }
    .content-more {
      box-shadow: inset 0 5px 7px -5px rgba($color-grey-black, 0.15);
      background: rgba($color-grey-mid, 0.1);
      border-top: $color-border 1px solid;
      .right-action {
        padding: 5px 10px 5px 0;
        text-align: right;
        .btn {
          cursor: pointer;
          white-space:nowrap;
          margin-top: 5px;
          margin-bottom: 5px;
          padding: 5px 6px 5px 5px;
          text-align: center;
          background-color: $color-brand-primary;
          color: $color-grey-white;
          border-radius: 2.5px;
          border: none;
          font-size: $font-size-medium;
          .icon {
            margin-right: 5px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .left-action {
        padding: 5px 0px 5px 10px;
        text-align: left;
        .btn {
          cursor: pointer;
          white-space:nowrap;
          margin-top: 5px;
          margin-bottom: 5px;
          padding: 5px 6px 5px 5px;
          text-align: center;
          background-color: $color-grey-mid;
          color: $color-grey-white;
          border-radius: 2.5px;
          border: none;
          font-size: $font-size-medium;
          .icon {
            margin-right: 5px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .item-foot {
      padding: 5px;
      border-top: 1px solid $color-border;

      .right-action {
        text-align: right;
      }
      .price {
        padding: 5px 10px 5px 0;
        text-align: right;
        strong {
          font-size: $font-size-headline;
          white-space:nowrap;
        }
        p {
          font-size: $font-size-small;
          white-space:nowrap;
        }
      }
      .details {
        padding: 5px 0 5px 10px;
        b {
          font-weight: bold;
        }
        h3 {
          font-size: $font-size-large;
          font-weight: bold;
        }
        .icon-star {
          color: $color-yellow-primary;
        }
        .small {
          color: $color-text-muted;
          font-size: $font-size-small;
          font-weight: normal;
        }
        p {
          font-size: $font-size-regular;
        }
        .blur {
          color: transparent;
          text-shadow: 0 0 5px rgba(0,0,0,0.5);
          &::selection {
            background: transparent;
          }
        }
      }
    }
    .content-items {
      >li {
        border-top: 1px dotted $color-border;
        &.disabled {
          text-decoration: line-through;
          color: $color-text-muted;
          background-color: $color-content;
        }
        &:first-child {
          border-top: none;
        }
      }
      .row {
        height: inherit;
      }
      .right-action {
        padding: 5px 10px 5px 0;
        text-align: right;
      }
      .image {
        img {
          height: 127px;
        }
      }
      .image-supplier {
        img {
          height: 240px;
          @include respond-to(sm) {
            height: 140px;
          }
        }
      }
      .image-orders {
        img {
          height: 127px;
        }
      }
      .orders {
        padding: 10px 15px 10px 10px;
        line-height: 1.5;
        .btn {
          cursor: pointer;
          margin-top: 37px;
        }
      }
      .details {
        padding: 0 0 0 10px;
        b {
          font-weight: bold;
        }
        h3 {
          padding-top: 5px;
          font-size: $font-size-large;
          font-weight: bold;
        }
        .icon-star {
          color: $color-yellow-primary;
        }
        .small {
          color: $color-text-muted;
          font-size: $font-size-small;
          font-weight: normal;
        }
        p {
          margin-bottom: 5px;
          font-size: $font-size-regular;
        }
      }
      .services-20 {
        padding: 5px 0 0 10px;
        ul {
          li {
            cursor: pointer;
            margin-right: 2.5px;
            color: $color-border;
            display: inline-block;
            .icon {
              font-size: $font-size-large;
            }
            &.active {
              color: $color-brand-primary;
            }
          }
        }
      }
      .services-10 {
        padding: 0 0 5px 10px;
        ul {
          li {
            width: 9%;
            cursor: pointer;
            margin-right: 2.5px;
            color: $color-border;
            display: inline-block;
            .icon {
              font-size: $font-size-large;
            }
            &.active {
              color: $color-brand-primary;
            }
          }
        }
      }
      .price {
        padding: 5px 10px 5px 0;
        text-align: right;
        strong {
          font-size: $font-size-headline;
          white-space:nowrap;
        }
        p {
          font-size: $font-size-small;
          white-space:nowrap;
        }
      }
    }
    .loader-container {
      &.loader-icon {
        &,
        &:before,
        &:after {
          background: $color-border;
        }
        & {
          color: $color-border;
        }
      }
    }
  }
}
