// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
  font-size: $font-size-regular;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;

  & {
    color: $color-text;
    background-color: $color-button;
    border: $color-button-border 1px solid inset;
    border-radius: 2.5px;
  }
  &a, a {
    @include on-event(true) {
      color: $color-text;
    }
  }
  &:hover {
    text-decoration: none;
    border-color: $color-button-hover-border;
  }
  &:active {
    background-color: $color-button-hover;
  }
  &::-moz-focus-inner {
    border: 0;
  }
  &.btn-whiteframe {
    margin-bottom: 15px;
  }
  &.btn-mrg {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &.btn-mrg-top {
    margin-top: 10px;
  }
  &.btn-mrg-bottom {
    margin-bottom: 10px;
  }
  &.btn-sm {
    padding: 4px 12px;
  }
  &.btn-md {
    padding: 7px 21px;
  }
  &.btn-lg {
    font-size: $font-size-medium;
    font-weight: bold;
    padding: 10px 15px;
  }
  &.btn-block {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
  }

  &.btn-transparent {
    color: $color-grey-white;
    background-color: rgba($color-grey-black, 0.1);
    border: $color-grey-white 1px solid;
    border-radius: 2.5px;
    &a {
      @include on-event(true) {
        color: $color-grey-white;
      }
    }
    &:hover {
      background-color: rgba($color-grey-black, 0.3);
    }
    &:active {
      background-color: rgba($color-grey-black, 0.5);
    }
  }
  &.btn-outline {
    color: $color-brand-primary;
    background-color: rgba($color-grey-black, 0.01);
    border: $color-brand-primary 1px solid;
    font-weight: bold;
    border-radius: 2.5px;
    &a, a {
      @include on-event(true) {
        text-decoration: none;
        color: $color-grey-white;
      }
    }
    &:hover {
      background-color: rgba($color-grey-black, 0.1);
    }
    &:active {
      background-color: rgba($color-grey-black, 0.01);
    }

    &.disabled {
      color: $color-text-muted;
      border: $color-text-muted 1px solid;
    }
  }
  &.btn-grey {
    color: $color-grey-white;
    background-color: rgba($color-grey-black, 0.25);
    border: rgba($color-grey-black, 0.01) 1px solid;
    border-radius: 2.5px;
    &a, a {
      @include on-event(true) {
        text-decoration: none;
        color: $color-grey-white;
      }
    }
    &:hover {
      background-color: rgba($color-grey-black, 0.35);
    }
    &:active {
      background-color: rgba($color-grey-black, 0.4);
    }
  }
  &.btn-green {
    color: $color-grey-white;
    background-color: $color-green-primary;
    border: $color-green-dark 1px solid;
    border-radius: 2.5px;
    &a, a {
      @include on-event(true) {
        text-decoration: none;
        color: $color-grey-white;
      }
    }
    &:hover {
      background-color: $color-green-dark;
    }
    &:active {
      background-color: $color-green-primary;
    }
  }
  &.btn-primary {
    color: $color-grey-white;
    background-color: $color-brand-primary;
    border: $color-brand-dark 1px solid;
    border-radius: 2.5px;
    &a, a {
      @include on-event(true) {
        text-decoration: none;
        color: $color-grey-white;
      }
    }
    &:hover {
      background-color: $color-brand-dark;
    }
    &:active {
      background-color: $color-brand-primary;
    }
  }
  &.btn-toactive {
    color: $color-grey-white;
    background-color: $color-brand-mid;
    border: $color-brand-dark 1px solid;
    border-radius: 2.5px;
    &a, a {
      @include on-event(true) {
        text-decoration: none;
        color: $color-grey-white;
        background-color: $color-brand-primary;
      }
    }
    &:hover {
      background-color: $color-brand-dark;
    }
    &:active {
      background-color: $color-brand-primary;
    }
  }
  &.btn-active {
    color: $color-grey-white;
    background-color: $color-brand-primary;
    border: $color-brand-dark 1px solid;
    border-radius: 2.5px;
    &a, a {
      @include on-event(true) {
        text-decoration: none;
        color: $color-grey-white;
      }
    }
    &:hover {
      background-color: $color-brand-dark;
    }
    &:active {
      background-color: $color-brand-primary;
    }
  }
}
