// -----------------------------------------------------------------------------
// This file contains all styles related to the input element.
// -----------------------------------------------------------------------------
.intl-tel-input {
  min-width: 500px !important;
}

form {
  .warn {
    background-color: $color-warning;
    border: 1px solid $color-warning-border;
    border-radius: 2.5px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: $font-size-small;

    &.ng-enter {
      transition: 0.5s;
      opacity: 0;
      max-height: 0px;
    }
    &.ng-enter-active {
      opacity: 1;
      max-height: 200px;
    }
    &.ng-leave {
      transition: 0.5s;
      opacity: 1;
      max-height: 200px;
    }
    &.ng-leave-active {
      opacity: 0;
      max-height: 0px;
    }
  }
}

label, .label-tag {
  position: relative;
  .uppercase {
    text-transform: uppercase;
  }
  .top {
    .transparent {
      color: transparent;
      user-select: none;
    }
    margin: 10px 0 0 0;
    padding: 0 0 5px 0;
    text-align: left;
    font-size: $font-size-regular;
    .label {
      display: inline-block;
    }
    .info {
      display: inline-block;
      color: $color-brand-mid;
      &:hover {
        cursor: pointer;
        color: $color-brand-primary;
      }
    }
  }
  .bottom {
    font-size: $font-size-small;
    text-align: left;
    padding: 2.5px 0 5px 0;
  }
  .input {
    height: 34px;
  }
  input[disabled] {
    background-color: $color-grey-light;
    color: $color-text-muted;
    @include on-event() {
      cursor: not-allowed;
      border-color: $color-border !important;
    }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .password-toggle {
    margin-top: 1px;
    margin-left: -36px;
    position: absolute;
    height: 35px;
    width: 35px;
    cursor:pointer;
    text-align: center;
    font-size: $font-size-large;
    line-height: 34px;
  }
  .loading-filed {
    margin-top: 0 !important;
    position: absolute;
    height: 35px;
    width: 35px;
    top: 32px;
    right: 0;
    cursor:pointer;
    text-align: center;
    font-size: $font-size-medium;
    line-height: 34px;
  }
  input, select, textarea, .input {
    box-shadow: none !important;
    width: 100%;
    padding: 10px;
    border-radius: 2.5px;
    border: 1px solid;
    border-color: $color-border !important;
    background-color: $color-grey-white;
    transition: all 0.30s ease-in-out;
    outline: none !important;
    &:focus, &:hover {
      outline: none !important;
    }
    &:hover {
      border-color: rgba($color-brand-primary, 0.5) !important;
    }
    &:focus {
      border-color: rgba($color-brand-primary, 0.6) !important;
      box-shadow: 0 0 5px 0px rgba($color-brand-primary, 0.5) !important;
    }
  }
  textarea {
    min-height: 100px;
    max-width: 100%;
  }
  select {
    padding: 10px 10px;
    appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, $color-grey-black 50%),
                      linear-gradient(135deg, $color-grey-black 50%, transparent 50%),
                      linear-gradient(to right, $color-grey-white, $color-grey-white);
    background-position: calc(100% - 20px) calc(1em + 2px),
                         calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    &:invalid {
      color: $color-text-muted;
    }
    option {
      color: $color-text;
      &:checked, &:hover {
        color: $color-grey-white;
        box-shadow: 0 0 10px 100px $color-brand-primary inset;
      }
      &:checked, &:hover {
        color: $color-grey-white;
        background: $color-brand-primary;
      }
    }
  }
}

[date-picker] {
  background-color: $color-grey-white;
  border: $color-border 1px solid;
  color:$color-text;
  box-shadow: 0px 0px 6px 0px rgba($color-grey-black, 0.35);
  table {
    td {
      span {
        color: $color-text !important;
        padding: 1px  !important;
        border-radius: 2.5px !important;
        &.muted {
          color: $color-text-muted !important;
        }
        &.disabled {
          background: none !important;
          color: $color-text-muted-light !important;
          cursor: default  !important;
          &:hover {
            padding: 0  !important;
            background-color: $color-content !important;
            color: $color-text-muted !important;
            border: $color-border 1px solid  !important;
            cursor: default  !important;
          }
          &:active, &.active, &:focus {
            background-color: none !important;
            pointer-events: none  !important;
          }
        }
        &.now {
          padding: 0  !important;
          background: $color-grey-light !important;
          border: $color-border 1px solid  !important;
          text-shadow: none !important;
        }
        &.active:not(.disabled), &:active:not(.disabled) {
          padding: 0  !important;
          background: $color-brand-primary !important;
          color: $color-grey-white !important;
          border: $color-brand-dark 1px solid  !important;
        }
        &:hover:not(.disabled) {
          padding: 0  !important;
          background-color: $color-brand-light !important;
          color: $color-grey-white !important;
          border: $color-brand-mid 1px solid  !important;
        }
      }
    }
    th:not(.weekday) {
      span {
        color: $color-text !important;
        border-radius: 2.5px !important;
        padding: 1px;
        &:hover {
          padding: 0  !important;
          background-color: $color-brand-light !important;
          color: $color-grey-white !important;
          border: $color-brand-mid 1px solid  !important;
        }
        &:active {
          padding: 0  !important;
          background: $color-brand-primary !important;
          color: $color-grey-white !important;
          border: $color-brand-dark 1px solid  !important;
        }
      }
    }
  }
  .year, .month, .minutes {
    width: 222px;
    td {
      width: 100%;
      span {
        height: 54px;
        line-height: 54px;
      }
    }
  }
  .date {
    width: 222px;
    td {
      span {
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
